<template>
  <div>
    <notifications group="notification" position="top right" />
    <div class="card card-custom gutter-b">
      <div class="card-header border-0 py-5">
        <h3 class="card-title align-items-center">
          <span class="card-label font-weight-bolder text-dark">
            {{ $t('PAGES.CRITICAL_DEVICES') }}
          </span>
        </h3>
      </div>

      <div class="card-body py-0">
        <div class="row mb-5">
          <div class="col-md-9">
            <div class="row mb-5">
              <div class="col-sm-4 col-md-4 col-xl">
                <vSelect
                  class="form-control form-control-solid form-control-lg"
                  placeholder="Select device"
                  :options="this.deviceOptions"
                  label="name"
                  v-model="selectedDevice"
                >
                  <template #option="{ name, tag, location }">
                    <span class="mr-3">{{ name }}</span>
                    <span class="badge badge-secondary mr-3">{{ tag }}</span>
                    <span>{{ location }}</span>
                  </template>
                </vSelect>
              </div>
              <div class="col-sm-4 col-md-4 col-xl mb-3 mb-xl-0">
                <div class="input-group">
                  <input
                    type="text"
                    v-model="deviceImei"
                    class="form-control form-control-solid form-control-lg"
                    placeholder="Enter device IMEI"
                  />
                </div>
              </div>
            </div>

            <div class="row mb-5">
              <div class="col-sm-4 col-md-4 col-xl mb-3 mb-xl-0">
                <vSelect
                  class="form-control form-control-solid form-control-lg"
                  placeholder="Select location"
                  :options="this.locationOptions"
                  label="title"
                  v-model="selectedLocation"
                >
                </vSelect>
              </div>
              <div class="col-sm-4 col-md-4 col-xl mb-3 mb-xl-0">
                <vSelect
                  class="form-control form-control-solid form-control-lg"
                  placeholder="Select lessor"
                  :options="this.lessorOptions"
                  label="name"
                  v-model="selectedLessor"
                >
                </vSelect>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-12 col-md-12 col-xl mb-3 mb-xl-0">
                <button
                  @click="getCriticalDevices"
                  class="btn btn-light-primary btn-block"
                  style="height: 44px"
                >
                  {{ $t('GENERAL.SEARCH') }}
                </button>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-12 col-xl mb-3 mb-xl-0">
                <button
                  @click="resetAll"
                  class="btn btn-light-danger btn-block"
                  style="height: 44px"
                >
                  Reset All
                </button>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <DatePicker
              ref="picker"
              v-model="range"
              mode="date"
              :attributes="attributes"
              :masks="masks"
              locale="hr"
              is-range
              is-expanded
              :columns="$screens({ default: 1, lg: 1 })"
              :max-date="new Date()"
            >
            </DatePicker>
            <button
              @click="resetDate"
              class="btn btn-light-danger btn-block mt-3"
              style="height: 44px"
            >
              {{ $t('GENERAL.RESET') }}
            </button>
          </div>
        </div>
        <div class="separator separator-dashed my-5"></div>
        <div class="row">
          <div class="col-md-12">
            <div v-if="!isLoading" class="table-responsive">
              <table
                class="table table-head-custom table-vertical-center table-head-bg"
              >
                <thead>
                  <tr class="text-uppercase">
                    <th style="min-width: 120px" class="pl-6">
                      <span class="pt-2"> Name</span>
                    </th>
                    <th>
                      <span class="pt-2"> {{ $t('GENERAL.LESSOR') }}</span>
                    </th>
                    <th>
                      <span class="pt-2"> {{ $t('GENERAL.LOCATION') }}</span>
                    </th>
                    <th style="min-width: 120px">Imei</th>
                    <th
                      class="text-right pr-6"
                      style="min-width: 160px; width: 270px; text-align: right"
                    >
                      Critical time
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="device in devices" :key="device.id">
                    <td
                      class="text-dark-75 font-weight-bolder mb-1 font-size-lg"
                    >
                      {{ device.name }}
                      <span
                        class="badge badge-primary mr-3 ml-3 text-white p-1"
                      >
                        {{ device.businessLabel }}
                      </span>
                      <span
                        class="label label-sm label-light-primary label-inline mr-2"
                      >
                        {{ device.tags }}
                      </span>
                    </td>
                    <td>{{ device.lessor }}</td>
                    <td>{{ device.location }}</td>
                    <td>{{ device.imei }}</td>
                    <td class="text-right">
                      <span
                        v-if="
                          device.criticalTime && device.criticalTime.days === 1
                        "
                        >{{ device.criticalTime.days }} day,
                      </span>
                      <span
                        v-if="
                          device.criticalTime &&
                          device.criticalTime.days !== 1 &&
                          device.criticalTime.days !== 0
                        "
                        >{{ device.criticalTime.days }} days,
                      </span>
                      <span
                        v-if="
                          device.criticalTime &&
                          (device.criticalTime.days !== 0 ||
                            device.criticalTime.hours !== 0)
                        "
                        >{{ device.criticalTime.hours }} hours and
                      </span>
                      <span>{{ device.criticalTime.minutes }} minutes </span>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div
                class="mt-10 overflow-auto d-flex justify-content-center align-items-center"
              >
                <b-pagination-nav
                  :link-gen="linkGen"
                  :number-of-pages="pagination.totalPages"
                  use-router
                  size="lg"
                ></b-pagination-nav>
              </div>
            </div>
            <div v-else class="col-12 text-center pb-5">
              <b-spinner variant="primary" label="Spinning"></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import ApiService from '@/core/services/api.service'
import { mapGetters } from 'vuex'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import format from 'date-fns/format'
import vSelect from 'vue-select'
import { getDaysHoursAndMinutes } from '../../../core/helpers/helpers'

export default {
  name: 'DevicesList',
  components: { DatePicker, vSelect },
  data() {
    return {
      devices: [],
      isDevicesLoaded: false,
      totalItems: 0,
      deviceName: '',
      deviceLocation: '',
      deviceLessor: '',
      deviceImei: '',
      selectedDevice: null,
      selectedLocation: null,
      selectedLessor: null,
      pagination: {
        totalPages: 1,
        currentPage: 1,
        itemsPerPage: 30,
      },
      isLoading: true,
      range: {
        start: null,
        end: null,
      },
      masks: {
        input: 'dd.MM.yyyy - HH:mm:ss',
      },
      attributes: [
        {
          key: 'today',
          highlight: true,
          dates: null,
        },
      ],
      deviceOptions: [],
      locationOptions: [],
      lessorOptions: [],
    }
  },
  computed: {
    ...mapGetters(['currentUserRole', 'currentUserCompany']),
    page() {
      return this.$route.query.page ?? 1
    },
  },
  mounted() {
    console.log('DOBAR DAN')
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Critical devices', route: '' },
    ])

    this.pagination.currentPage = this.$route.query.page || 1

    this.getCriticalDevices()
    this.getDeviceSearchOptions()
    this.getLocationSearchOptions()
    this.getLessorSearchOptions()
  },
  watch: {
    '$route.query.page'(newPage) {
      this.pagination.currentPage = newPage || 1
      this.getCriticalDevices()
    },
    selectedDevice() {
      this.pagination.currentPage = 1
      this.$router.replace({ query: '' })
    },
    deviceImei() {
      this.pagination.currentPage = 1
      this.$router.replace({ query: '' })
    },
    selectedLocation() {
      this.pagination.currentPage = 1
      this.$router.replace({ query: '' })
    },
    selectedLessor() {
      this.pagination.currentPage = 1
      this.$router.replace({ query: '' })
    },
    range() {
      this.pagination.currentPage = 1
      this.$router.replace({ query: '' })
    },
  },
  methods: {
    linkGen(pageNum) {
      return pageNum === 1 ? '?' : `?page=${pageNum}`
    },
    getCriticalDevices() {
      this.isLoading = true

      let endpointPath = null
      this.searchQuery = ''
      let after = null
      let before = null

      if (this.range && this.range.start && this.range.end) {
        after = format(this.range.start, "yyyy-MM-dd'T'00:00:00")
        if (
          this.range.end.toISOString().split('T')[0] ===
          new Date().toISOString().split('T')[0]
        ) {
          before = format(new Date(), "yyyy-MM-dd'T'HH:mm:ss")
        } else {
          before = format(this.range.end, "yyyy-MM-dd'T'23:59:59")
        }
      }

      if (this.selectedDevice) {
        this.searchQuery += `&device_id=${this.selectedDevice.id}`
      }
      if (this.selectedLocation) {
        this.searchQuery += `&location_id=${this.selectedLocation.id}`
      }
      if (this.selectedLessor) {
        this.searchQuery += `&lessor_id=${this.selectedLessor.id}`
      }
      if (this.deviceLocation)
        this.searchQuery += `&location=${this.deviceLocation}`
      if (this.deviceLessor) this.searchQuery += `&lessor=${this.deviceLessor}`
      if (this.deviceImei) this.searchQuery += `&imei=${this.deviceImei}`
      if (after && before) {
        this.searchQuery += `&after=${after}&before=${before}`
      }

      if (
        this.currentUserRole === 'ROLE_ROOT' ||
        this.currentUserRole === 'ROLE_SUPER_ADMIN'
      ) {
        endpointPath = `?itemsPerPage=${this.pagination.itemsPerPage}&page=${this.pagination.currentPage}${this.searchQuery}`
      } else if (this.currentUserRole === 'ROLE_ADMIN') {
        endpointPath = `?itemsPerPage=${
          this.pagination.itemsPerPage
        }&company_id=${this.currentUserCompany.replace(
          '/api/v1/companies/',
          ''
        )}&page=${this.pagination.currentPage}${this.searchQuery}`
      }

      if (endpointPath) {
        ApiService.get('critical_device_intervals', endpointPath)
          .then(({ data }) => {
            this.devices = [...data['hydra:member']].map((device) => {
              return {
                name: device.name,
                imei: device.imei,
                location: device.location,
                lessor: device.lessor,
                criticalTime: getDaysHoursAndMinutes(device.criticalTime),
                businessLabel: device.businessLabel,
                tags: device.tags,
              }
            })
            this.pagination.totalPages = Math.ceil(
              data['hydra:totalItems'] / this.pagination.itemsPerPage
            )
          })
          .finally(() => {
            this.isLoading = false
          })
      }
    },
    getDeviceSearchOptions() {
      if (
        this.currentUserRole === 'ROLE_ROOT' ||
        this.currentUserRole === 'ROLE_SUPER_ADMIN'
      ) {
        ApiService.get('select-critical-device').then(({ data }) => {
          this.deviceOptions = data
        })
      } else if (this.currentUserRole === 'ROLE_ADMIN') {
        ApiService.get(
          `select-critical-device`,
          `?company_id=${this.currentUserCompany.replace(
            '/api/v1/companies/',
            ''
          )}`
        ).then(({ data }) => {
          this.deviceOptions = data
        })
      }
    },
    getLocationSearchOptions() {
      if (
        this.currentUserRole === 'ROLE_ROOT' ||
        this.currentUserRole === 'ROLE_SUPER_ADMIN'
      ) {
        ApiService.get('select-critical-location').then(({ data }) => {
          this.locationOptions = data
        })
      } else if (this.currentUserRole === 'ROLE_ADMIN') {
        ApiService.get(
          'select-critical-location',
          `?company_id=${this.currentUserCompany.replace(
            '/api/v1/companies/',
            ''
          )}`
        ).then(({ data }) => {
          this.locationOptions = data
        })
      }
    },
    getLessorSearchOptions() {
      if (
        this.currentUserRole === 'ROLE_ROOT' ||
        this.currentUserRole === 'ROLE_SUPER_ADMIN'
      ) {
        ApiService.get('select-critical-lessor').then(({ data }) => {
          this.lessorOptions = data
        })
      } else if (this.currentUserRole === 'ROLE_ADMIN') {
        ApiService.get(
          'select-critical-lessor',
          `?company_id=${this.currentUserCompany.replace(
            '/api/v1/companies/',
            ''
          )}`
        ).then(({ data }) => {
          this.lessorOptions = data
        })
      }
    },
    resetDate() {
      this.range.start = null
      this.range.end = null

      this.$refs.picker.updateValue({
        start: null,
        end: null,
      })
    },
    resetAll() {
      this.resetDate()
      this.selectedDevice = null
      this.selectedLocation = null
      this.selectedLocation = null
      this.deviceImei = null
    },
  },
}
</script>

<style lang="scss">
@import 'vue-select/src/scss/vue-select.scss';

.modal-body {
  img {
    width: 100%;
  }
}

.vs__dropdown-toggle {
  border: none;
}

.vs__search::placeholder {
  color: #b5b5c3;
}
</style>
